<header>
  <h2>
    {{ 'payments.title' | translate }}
    <button type="button" mat-raised-button (click)="addPayment()">{{ 'payments.addPayment' | translate }}</button>
  </h2>
</header>

<div class="card"
     infiniteScroll
     [infiniteScrollContainer]="'.main'"
     [fromRoot]="true"
     [infiniteScrollDisabled]="lastPageReached"
     (scrolled)="onScroll()">
  <app-transactions-list type="payments" [transactions]="payments$ | async" (remove)="removePayment($event)"></app-transactions-list>
</div>

<div class="infinite-scroll-loading" *ngIf="loading">
  <hr><span>{{ 'common.loadingData' | translate }}</span>
</div>
