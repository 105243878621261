<header>
  <div class="cell">&nbsp;</div>
  <div class="cell" *ngIf="type === 'expenses'">{{ 'common.transaction' | translate }}</div>
  <div class="cell">{{ 'common.date' | translate }}</div>
  <div class="cell">{{ 'common.notes' | translate }}</div>
  <div class="cell amount">{{ 'common.amount' | translate }}</div>
  <div class="cell" *ngIf="editable"></div>
</header>

<ng-container *ngIf="transactions.length">
  <div class="row" *ngFor="let transaction of transactions">
    <div class="cell payer">
      <app-avatar *ngIf="transaction.payer" [user]="transaction.payer"></app-avatar>
    </div>
    <div class="cell description" *ngIf="type === 'expenses'">{{ transaction.description }}<br><span class="category" *ngIf="transaction.category">{{ transaction.category.name }}</span></div>
    <div class="cell date">{{ transaction.date }}</div>
    <div class="cell notes">{{ transaction.notes }}</div>
    <div class="cell amount">{{ transaction.amount | number: '1.2' }}</div>
    <div class="cell actions" *ngIf="editable">
      <button mat-icon-button class="edit" (click)="edit(transaction)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button class="remove" (click)="doRemove(transaction)">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
