<header>
  <h1><img src="../../assets/logo-white-large.png" class="logo"> Oreka</h1>
</header>

<ul class="menu">
  <li><a routerLink="/dashboard">{{ 'menu.dashboard' | translate }}</a></li>
  <li><a routerLink="/expenses">{{ 'menu.expenses' | translate }}</a></li>
  <li><a routerLink="/payments">{{ 'menu.payments' | translate }}</a></li>
  <li><a (click)="logout()">{{ 'menu.logout' | translate }}</a></li>
</ul>
