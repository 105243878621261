<h2 mat-dialog-title>{{ (form.value.id ? 'expenseForm.editTitle' : 'expenseForm.addTitle') | translate }}</h2>

<mat-dialog-content>
  <form [formGroup]="form">
    <div class="row">
      <div class="field date" [class.-error]="form.controls.date.touched && form.controls.date.invalid">
        <label for="date">{{ 'common.date' | translate }}</label>
        <input type="text" id="date" class="form-control" formControlName="date" [matDatepicker]="picker">
        <span class="suffix"><mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle></span>
        <mat-datepicker #picker></mat-datepicker>
      </div>

      <div class="field -full-width">
        <label for="description">{{ 'common.description' | translate }}</label>

        <input type="text" id="description" class="form-control" formControlName="description" [matAutocomplete]="auto" (input)="onDescriptionChange($event)">

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onDescriptionSelected($event)">
          <mat-option *ngFor="let option of filteredDescriptionOptions" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>

    <div class="row">
      <div class="field" [class.-error]="form.controls.amount.touched && form.controls.amount.invalid">
        <label for="amount">{{ 'common.amount' | translate }}</label>
        <div class="input-group">
          <input type="number" id="amount" class="form-control" formControlName="amount" (change)="onPayerShareChange()">
          <span class="suffix">$</span>
        </div>
      </div>

      <div class="field radio" [class.-error]="form.controls.payerId.touched && form.controls.payerId.invalid">
        <label>{{ 'expenseForm.paidBy' | translate }}</label>
        <div class="input-group">
          <ng-container *ngFor="let member of (coupleMembers$ | async)">
            <input type="radio" id="payer-{{ member.id }}" formControlName="payerId" [value]="member.id" (change)="onPayerShareChange()"> <label for="payer-{{ member.id }}">{{ member.firstName }}</label>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="field shares">
        <label>{{ 'expenseForm.share' | translate }}</label>
        <div class="partners">
          <div class="partner">{{ (user$ | async).firstName }}</div>
          <div class="partner">{{ (partner$ | async).firstName }}</div>
        </div>
        <mat-slider
          [max]="100"
          [min]="0"
          [step]="10"
          [value]="costSplit"
          (input)="onCostSplitChange($event.value)">
        </mat-slider>
        <div class="amounts">
          <div class="amount">{{ userShare | number: '1.2' }} $</div>
          <div class="amount">{{ partnerShare | number: '1.2' }} $</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="field -full-width" [class.-error]="form.controls.categoryId.touched && form.controls.categoryId.invalid">
        <label for="category">{{ 'common.category' | translate }}</label>
        <select id="category" class="form-control" formControlName="categoryId">
          <option value=""> </option>
          <option *ngFor="let category of (categories$ | async)" [value]="category.id">
            {{ category.name }}
          </option>
        </select>
      </div>

      <div class="field notes -full-width" [class.-error]="form.controls.notes.touched && form.controls.notes.invalid">
        <label for="notes">{{ 'common.notes' | translate }}</label>
        <input type="text" id="notes" class="form-control" formControlName="notes">
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'common.actions.cancel' | translate }}</button>
  <button mat-raised-button (click)="submit()">{{ 'common.actions.save' | translate }}</button>
</mat-dialog-actions>
