<h2 mat-dialog-title>
  {{ 'expenses.import' | translate }}
  <ng-container *ngIf="file"> - {{ file.name }}</ng-container>
</h2>

<mat-dialog-content>
  <ng-container *ngIf="!loading">
    <div *ngIf="!file" class="file-selector">
      <mat-form-field>
        <mat-select [placeholder]="'import.fileFormat' | translate" [(ngModel)]="fileFormat">
          <mat-option *ngFor="let format of formats" [value]="format">
            {{ 'import.formats.' + format | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button type="button" mat-raised-button (click)="fileInput.click()">
        <mat-icon>library_add</mat-icon>
        <span>{{ 'import.selectFile' | translate }}</span>
        <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;"/>
      </button>
    </div>

    <div *ngIf="file && operations.length" class="operations">
      <table #opTable mat-table [dataSource]="operations">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="masterToggle()" [checked]="allSelected()" [indeterminate]="someSelected()"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (change)="toggleOperation(row)" [checked]="row.selected"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="opInfos">
          <th mat-header-cell *matHeaderCellDef>{{ 'import.details' | translate }}</th>
          <td mat-cell *matCellDef="let op">
            <strong>{{ op.amount | currency }}</strong><br>
            <small>{{ op.date.format('DD-MM-YYYY') }}</small>
          </td>
        </ng-container>

        <ng-container matColumnDef="opDesc">
          <th mat-header-cell *matHeaderCellDef>{{ 'import.description' | translate }}</th>
          <td mat-cell *matCellDef="let op">
            <ng-container *ngIf="!op.selected">
              <strong *ngIf="op.bankDescription">{{ op.bankDescription }}</strong>
              <br *ngIf="op.bankDescription && op.helpText">
              <small *ngIf="op.helpText">{{ op.helpText }}</small>
            </ng-container>

            <ng-container *ngIf="op.selected">
              <mat-form-field>
                <input matInput type="text" [(ngModel)]="op.description" [placeholder]="'import.description' | translate">
              </mat-form-field>

              <mat-form-field>
                <input matInput type="text" [(ngModel)]="op.comment" [placeholder]="'import.note' | translate">
              </mat-form-field>

              <mat-form-field>
                <mat-select [placeholder]="'common.category' | translate" [(ngModel)]="op.categoryId">
                  <mat-option *ngFor="let cat of categories" [value]="cat.id">
                    {{ cat.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay" [class.selected]="row.selected"></tr>
      </table>
    </div>

    <div *ngIf="file && !operations.length" class="empty">
      <p>{{ 'import.noOp' | translate }}</p>

      <button type="button" mat-raised-button (click)="reset()">
        <mat-icon>library_add</mat-icon>
        <span>{{ 'import.reset' | translate }}</span>
      </button>
    </div>
  </ng-container>

  <div *ngIf="loading" class="loading">
    <mat-spinner></mat-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'common.actions.cancel' | translate }}</button>
  <button mat-raised-button [disabled]="!someSelected()" (click)="submit()">{{ 'common.actions.save' | translate }}</button>
</mat-dialog-actions>
