<header>
  <h2>
    {{ 'expenses.title' | translate }}
    <button type="button" mat-raised-button (click)="addExpense()">{{ 'expenses.addExpense' | translate }}</button>
    <button type="button" mat-raised-button (click)="importFromFile()">{{ 'expenses.import' | translate }}</button>
  </h2>
</header>

<div class="card"
     infiniteScroll
     [infiniteScrollContainer]="'.main'"
     [fromRoot]="true"
     [infiniteScrollDisabled]="lastPageReached"
     (scrolled)="onScroll()">
  <app-transactions-list [transactions]="expenses$ | async" (remove)="removeExpense($event)"></app-transactions-list>
</div>

<div class="infinite-scroll-loading" *ngIf="loading">
  <hr><span>{{ 'common.loadingData' | translate }}</span>
</div>
