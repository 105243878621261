<header>
  <h2>
    {{ 'dashboard.title' | translate }}
    <button type="button" mat-raised-button (click)="addExpense()">{{ 'dashboard.addExpense' | translate }}</button>
    <button type="button" mat-raised-button (click)="importFromFile()">{{ 'expenses.import' | translate }}</button>
  </h2>
</header>

<div class="row">
  <div class="left">
    <div class="card">
      <h3>{{ 'dashboard.lastExpenses' | translate }}</h3>
      <app-transactions-list [editable]="false" [transactions]="expenses$ | async"></app-transactions-list>
    </div>

    <div class="card">
      <app-expense-widget></app-expense-widget>
    </div>
  </div>

  <div class="right">
    <div class="card balance" [class.-creditor]="(user$ | async)?.balance >= 0" [class.-debtor]="(user$ | async)?.balance < 0">
      <span class="description" *ngIf="(user$ | async)?.balance > 0">{{ (partner$ | async)?.firstName }} {{ 'dashboard.owesYou' | translate }}</span>
      <span class="description" *ngIf="(user$ | async)?.balance < 0">{{ 'dashboard.youOwe' | translate }}</span>
      <span class="description" *ngIf="(user$ | async)?.balance === 0">{{ 'dashboard.allSquare' | translate }}</span>

      <h2 class="amount">{{ (user$ | async)?.balance | number: '1.2' }} $</h2>
    </div>

    <div class="card">
      <h3>{{ 'dashboard.lastPayments' | translate }}</h3>
      <app-transactions-list [editable]="false" type="'payments'" [transactions]="payments$ | async"></app-transactions-list>
    </div>
  </div>
</div>
