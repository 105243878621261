<h2 mat-dialog-title>{{ (form.value.id ? 'paymentForm.editTitle' : 'paymentForm.addTitle') | translate }}</h2>

<mat-dialog-content>
  <form [formGroup]="form">
    <div class="row">
      <div class="field" [class.-error]="form.controls.amount.touched && form.controls.amount.invalid">
        <label for="amount">{{ 'common.amount' | translate }}</label>
        <div class="input-group">
          <input type="number" id="amount" class="form-control" formControlName="amount">
          <span class="suffix">$</span>
        </div>
      </div>

      <div class="field notes -full-width" [class.-error]="form.controls.notes.touched && form.controls.notes.invalid">
        <label for="notes">{{ 'common.notes' | translate }}</label>
        <input type="text" id="notes" class="form-control" formControlName="notes">
      </div>
    </div>

    <div class="row">
      <div class="field radio" [class.-error]="form.controls.payerId.touched && form.controls.payerId.invalid">
        <label>{{ 'paymentForm.paidBy' | translate }}</label>
        <div class="input-group">
          <ng-container *ngFor="let member of (coupleMembers$ | async)">
            <input type="radio" id="payer-{{ member.id }}" formControlName="payerId" [value]="member.id"> <label for="payer-{{ member.id }}">{{ member.firstName }}</label>
          </ng-container>
        </div>
      </div>

      <div class="field" [class.-error]="form.controls.date.touched && form.controls.date.invalid">
        <label for="date">{{ 'common.date' | translate }}</label>
        <input type="text" id="date" class="form-control" formControlName="date" [matDatepicker]="picker">
        <span class="suffix"><mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle></span>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'common.actions.cancel' | translate }}</button>
  <button mat-raised-button (click)="submit()">{{ 'common.actions.save' | translate }}</button>
</mat-dialog-actions>
